<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Book Report
              <v-spacer></v-spacer>
             <print-buttons
              v-if="$auth.can('library-download') && book_records.length > 0"
              :passAuthToReport="true"
              labelPdf="Download Pdf"
              labelExcel="Download Excel"
              :downloadUrl="downloadUrl"
              :pdf="false"
              :excel="true"
            ></print-buttons>
          </v-card-title>
          <v-card outlined>
            <v-card-title outlined>
              <v-flex xs3>
                <v-select
                  autocomplete="off"
                  :items="authors"
                  label="Author"
                  outlined
                  dense
                  class="pa-0"
                  @click="getAuthors(false)"
                  v-model="book_info.author_id"
                ></v-select>
              </v-flex>

              <v-flex xs3>
                <v-select
                  autocomplete="off"
                  :items="vendors"
                  label="Vendor"
                  outlined
                  dense
                  class="pa-0"
                  @click="getVendor(false)"
                  v-model="book_info.vendor_id"
                ></v-select>
              </v-flex>
              <v-flex xs3>
                <v-select
                  autocomplete="off"
                  :items="publishers"
                  label="Publisher"
                  outlined
                  dense
                  class="pa-0"
                  @click="getPublisher(false)"
                  v-model="book_info.publisher_id"
                ></v-select>
              </v-flex>
              <v-flex xs2>
                <search-button permission="library-read" @action="filterBook()">
                  Search
                </search-button>
              </v-flex>
              <v-flex xs1>
                <v-spacer></v-spacer>
                <v-btn
                  @click="moreFilterDialogue = true"
                  color="primary"
                  style="margin-top: -20px; float: right"
                  elevation="2"
                  icon
                  ><v-icon>filter_alt</v-icon></v-btn
                >
              </v-flex>
            </v-card-title>
          </v-card>
          <br />
          <v-data-table
            :headers="headers"
            :items="book_records"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">{{ item.name }}</td>
                <td class="text-xs-left">{{ item.accession_no }}</td>
                <td class="text-xs-left">
                   <v-chip-group
                      active-class="primary--text"
                      column
                    >
                    <!-- {{ item.authors }} -->
                      <v-chip
                        small label
                        v-for="author in item.authors"
                        :key="author.id"
                      >
                        {{ author.name }}
                      </v-chip>
                    </v-chip-group>
                </td>
                <td class="text-xs-left">{{ item.vendor }}</td>
                <td class="text-xs-left">{{ item.publisher }}</td>
                <td class="text-xs-left">{{ item.cost.currency() }}</td>
                <td class="text-xs-left">{{ item.quantity }}</td>
              </tr>
            </template>
          </v-data-table>
          <v-dialog v-model="moreFilterDialogue" persistent max-width="550">
            <v-card>
              <v-card-title class="title pa-3 primary white--text">
                <v-icon class="mr-2 white--text">filter_alt</v-icon>
                More Filters
              </v-card-title>

              <v-card-title class="title pa-3">
                <br />
                <v-flex xs12>
                  <v-select
                    autocomplete="off"
                    :items="donors"
                    label="Donor"
                    outlined
                    dense
                    class="pa-0"
                    @click="getDonor(false)"
                    v-model="book_info.donor_id"
                  ></v-select>
                </v-flex>

                <v-flex xs12>
                  <v-select
                    autocomplete="off"
                    :items="racks"
                    label="Rack"
                    outlined
                    dense
                    class="pa-0"
                    @click="getRack()"
                    v-model="book_info.rack_id"
                  ></v-select>
                </v-flex>

                <v-flex xs12>
                  <v-select
                    autocomplete="off"
                    :items="sectors"
                    label="Sector"
                    outlined
                    dense
                    class="pa-0"
                    @click="getSector()"
                    v-model="book_info.sector_id"
                  ></v-select>
                </v-flex>

                <v-flex xs12>
                  <v-select
                    autocomplete="off"
                    :items="categories"
                    label="Category"
                    outlined
                    dense
                    class="pa-0"
                    @click="getCategories()"
                    v-model="book_info.category_id"
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    autocomplete="off"
                    :items="language"
                    label="Language"
                    outlined
                    dense
                    class="pa-0"
                    width="35"
                    @click="getLanguages()"
                    v-model="book_info.language"
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <br />
                  <v-calendar-field
                    id-val="year"
                    v-model="book_info.published_year"
                    label="Published Year"
                  >
                  </v-calendar-field>
                </v-flex>
                <v-flex xs12>
                  <br />
                  <v-text-field
                    autocomplete="off"
                    label="Cost"
                    class="pa-0"
                    outlined
                    dense
                    type="number"
                    v-model="book_info.cost"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    autocomplete="off"
                    label="Quantity"
                    class="pa-0"
                    outlined
                    dense
                    type="number"
                    v-model="book_info.quantity"
                  />
                </v-flex>
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="warning"
                  outlined
                  small
                  @click="moreFilterDialogue = false"
                  >Close</v-btn
                >
                <v-btn color="success" @click="filterBook()" small>
                  Filter Now
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import Form from "@/library/Form";

import bookFilter from "@/library/BookFilter";
import Mixins from "@/library/Mixins";

export default {
  mixins: [bookFilter, Mixins],
  data: () => ({
    moreFilterDialogue: false,
    pagination: {
      itemsPerPage: 50,
    },
    footerProps: {
      "items-per-page-options": [10, 25, 50, 100, -1],
    },
    book_info: {
      author_id: "",
      vendor_id: "",
      donor_id: "",
      publisher_id: "",
      rack_id: "",
      sector_id: "",
      category_id: "",
      language: "",
      published_year: "",
      cost: "",
      quantity: "",
    },
    form: new Form({}, "library/api/filter-book"),
    book_records: [],

    headers: [
      { text: "#", align: "left", value: "id", sortable: false },

      {
        text: "BookName",
        align: "left",
        value: "name",
        sortable: false,
      },
      {
        text: "Accession.No",
        align: "left",
        value: "accession_no",
        sortable: false,
      },
      { text: "Authors", align: "left", value: "authors", sortable: false },
      {
        text: "Vendor",
        align: "left",
        value: "vendor",
        sortable: false,
      },
      { text: "Publisher", align: "left", value: "publisher", sortable: false },
      { text: "Cost", align: "left", value: "cost", sortable: false },
      { text: "Quantity", align: "left", value: "quantity", sortable: false },
    ],
    authors: [],
    downloadUrl:'',
  }),
  watch: {
    pagination: function () {
      this.filterBook();
    },
  },
  methods: {
    filterBook() {
      let extraParams =
        "&authorId=" +
        this.book_info.author_id.toString() +
        "&vendorId=" +
        this.book_info.vendor_id.toString() +
        "&donorId=" +
        this.book_info.donor_id.toString() +
        "&publisherId=" +
        this.book_info.publisher_id.toString() +
        "&rackId=" +
        this.book_info.rack_id.toString() +
        "&sectorId=" +
        this.book_info.sector_id.toString() +
        "&categoryId=" +
        this.book_info.category_id.toString() +
        "&language=" +
        this.book_info.language +
        "&publishedYear=" +
        this.book_info.published_year.toString() +
        "&cost=" +
        this.book_info.cost + 
        "&quantity=" + this.book_info.quantity;
      this.form
        .get(null, this.queryString(extraParams))
        .then(({ data }) => {
          this.downloadUrl = data.download_url;
          this.book_records = data.data;
          this.form.items = data;
        })
        .catch((e) => {});
    },
  },
};
</script>
